<template>
  <div class="text-center">
    <img src="@/assets/img/empty-states/empty.svg" class="img-fluid mb-4" width="150" height="150" alt="Empty State Image">
    <p class="typography-headline-md fw-500 mb-2">{{ title }}</p>
    <p class="subtitle-1 text-muted mb-3">{{ subtitle }}</p>
    <router-link v-if="btnLink" :to="btnLink" class="btn btn-primary btn-round ripple ripple-dark px-5">
      {{ btnText }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: [
    'title',
    'subtitle',
    'btnLink',
    'btnText',
  ]
}
</script>