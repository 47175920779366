<template>
  <div>
    <TheNavbar/>
    <div class="container mt-4">

      <!-- MENU TITLE -->
      <div class="row mb-3">
        <div class="col-6 align-self-center animate fadeIn">
          <h1 class="fw-500 typography-headline-lg">Menu maker</h1>
        </div>
        <div v-if="menuURL" class="col-6 text-end">
          <a :href="menuURL" target="_blank"
             class="btn btn-default-outline ripple ripple-secondary animate fadeIn"
             style="--animation-index: 0.7s">
            <i class="material-icons-outlined me-1">&#xef6b;</i>
            Preview menu
          </a>

        </div>
      </div>

      <SelectRestaurantLocation @locationChanged="locationChanged"/>

      <!-- MENU FORM -->
      <div class="row justify-content-center mb-3">
        <div class="col-md-12">
          <div class="alert alert-sm alert-info mb-1">
            <i class="material-icons-outlined icon-wrap me-1">&#xe8fd;</i>
            Need some help? Send us an email at: <a href="mailto:contact@menuffy.com" class="text-secondary">contact@menuffy.com</a>
          </div>
        </div>

        <div class="col-md-12">
          <hr>
          <span v-if="selectedCategory" class="badge badge-body px-3">
            Selected: {{ selectedCategory.name }}
            <button @click="resetCategory" class="btn btn-sm btn-link m-0 p-0" type="button"
                    aria-label="Reset category">X</button>
          </span>
          <!-- TABS -->
          <ul class="nav nav-tabs" id="myTab" role="tablist">

            <li class="nav-item" role="presentation">
              <button class="nav-link ripple ripple-primary"
                      ref="categoriesTab"
                      @click="selectTab('categories')"
                      data-bs-target="#categories"
                      type="button"
                      role="tab" aria-controls="categories" aria-selected="false">
                Categories
              </button>
            </li>

            <li class="nav-item" role="presentation">
              <button class="nav-link ripple ripple-primary"
                      ref="itemsTab"
                      @click="selectTab('items')"
                      data-bs-target="#items"
                      type="button"
                      role="tab" aria-controls="items" aria-selected="false">
                Items
              </button>
            </li>

            <!--                        <li class="nav-item" role="presentation">
                                      <button class="nav-link"
                                              ref="modifiersTab"
                                              @click="selectTab('modifiers')"
                                              data-bs-target="#modifiers"
                                              type="button"
                                              role="tab" aria-controls="modifiers" aria-selected="false">
                                        <i class="material-icons-outlined me-1">&#xef4f;</i>
                                        Modifiers
                                      </button>
                                    </li>-->
          </ul>

          <!-- TABS CONTENT -->
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade" id="categories" role="tabpanel" aria-labelledby="categories-tab">
              <MenuCategories v-if="selectedLocationId"
                              :restaurant-location-id="selectedLocationId"
                              :selected-category="selectedCategory"
                              @categorySelect="selectCategory"
                              @categoryDeleted="categoryDeleted"/>
            </div>
            <div class="tab-pane fade" id="items" role="tabpanel" aria-labelledby="items-tab">
              <MenuItems v-if="selectedLocationId"
                         :restaurant-location-id="selectedLocationId"
                         :selected-category="selectedCategory"/>
            </div>
            <!--                        <div class="tab-pane fade" id="modifiers" role="tabpanel" aria-labelledby="modifiers-tab">
                                      <MenuModifiers/>
                                    </div>-->
          </div>

        </div>

      </div>

    </div>
  </div>
</template>

<script>
import TheNavbar from "@/components/TheNavbar";
import SelectRestaurantLocation from '@/components/SelectRestaurantLocation';
import MenuCategories from "@/components/MenuCategories";
import MenuItems from "@/components/MenuItems";
import {doc, getDoc} from 'firebase/firestore'
import {Tab} from 'bootstrap'
import {db, firebaseAuth} from "@/firebase";

export default {
  components: {MenuItems, TheNavbar, SelectRestaurantLocation, MenuCategories},
  data() {
    return {
      categories: [],
      loading: false,
      selectedLocationId: null,
      selectedCategory: null,
      restaurantData: null,

      itemsTab: null,
      categoriesTab: null,
    }
  },
  computed: {
    menuURL() {
      return this.restaurantData ? `https://${this.restaurantData.subdomain}.menuffy.com` : null
    }
  },
  mounted() {
    this.switchTab()
    this.fetchRestaurantData()
  },
  methods: {
    async fetchRestaurantData() {
      const restaurantSnap = await getDoc(doc(db, 'restaurants', firebaseAuth.currentUser.uid))
      this.restaurantData = {id: restaurantSnap.id, ...restaurantSnap.data()}
    },
    async selectTab(tab) {
      await this.$router.replace({name: 'MenuTab', params: {tab}})
      this.switchTab()
    },
    switchTab() {
      const categoriesElem = this.$refs.categoriesTab
      this.categoriesTab = new Tab(categoriesElem)

      const itemsElem = this.$refs.itemsTab
      this.itemsTab = new Tab(itemsElem)

      // const modifiersElem = this.$refs.modifiersTab
      // const modifiersTab = new Tab(modifiersElem)

      switch (this.$route.params.tab) {
        case 'categories':
          this.categoriesTab.show()
          break
        case 'items':
          this.itemsTab.show()
          break
          // case 'modifiers':
          //   modifiersTab.show()
          //   break;
        default:
          this.$router.replace('/menu/categories')
      }
    },
    async locationChanged(id) {
      if (!id) {
        console.debug("[menu] No locations available")
        await this.$router.replace({name: 'Locations'})

        return
      }

      this.selectedLocationId = id
      this.selectedCategory = null
      await this.$router.replace({name: 'MenuTab', params: {tab: 'categories'}})
      this.switchTab()
    },
    selectCategory(category) {
      this.selectedCategory = category
      this.itemsTab.show()
    },
    categoryDeleted(categoryId) {
      if (this.selectedCategory.id === categoryId) {
        this.selectedCategory = null
      }
    },
    resetCategory() {
      this.selectedCategory = null
      this.categoriesTab.show()
    },
  }
}
</script>
