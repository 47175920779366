<template>
  <div class="scrolling-wrapper d-flex flex-row flex-nowrap">

    <div v-for="(loc, index) in restaurantLocations" :key="index+'-button-location'">
      <button class="btn btn-stores ripple ripple-secondary px-3 animate slideIn"
              :style="{'--animation-index': 'calc(0.5s + 0.' + index + 's)'}"
              :value="loc.id"
              :disabled="isLoading || selectedRestaurantLocationId === loc.id"
              @click="changeRestaurantLocation(loc.id)">
        <i :class="['material-icons-outlined me-1', selectedRestaurantLocationId !== loc.id ? 'text-secondary' : '' ]">
          <span v-if="selectedRestaurantLocationId !== loc.id">&#xea12;</span>
          <span v-else>&#xe876;</span>
        </i>
        {{ loc.name }}
      </button>
    </div>

  </div>
</template>

<script>
import {collection, query, getDocs, orderBy} from "firebase/firestore";
import {db, firebaseAuth} from "@/firebase";

export default {
  data() {
    return {
      restaurantLocations: [],
      selectedRestaurantLocationId: null,
      isLoading: false,
    }
  },
  mounted() {
    this.fetchRestaurantLocations().then(() => this.fetchSelectedRestaurantLocation())
  },
  methods: {
    fetchRestaurantLocations() {
      this.isLoading = true
      const locationQuery = query(
          collection(db, 'users', firebaseAuth.currentUser.uid, 'restaurantLocations'),
          orderBy('createdAt', 'desc')
      )
      return getDocs(locationQuery)
          .then(querySnap => querySnap.docs.map(snap => ({id: snap.id, ...snap.data()})))
          .then(locations => {
            this.restaurantLocations = locations
          })
          .catch(console.error)
          .finally(() => this.isLoading = false)
    },

    fetchSelectedRestaurantLocation() {
      const lastLocationSaved = localStorage.getItem('lastSelectedRestaurantLocation')
      const location = this.restaurantLocations.find(loc => loc.id === lastLocationSaved)

      this.selectedRestaurantLocationId =
          location?.id ?? (this.restaurantLocations.length > 0 ? this.restaurantLocations[0].id : null)
      this.changeRestaurantLocation(this.selectedRestaurantLocationId)
    },

    changeRestaurantLocation(id) {
      localStorage.setItem('lastSelectedRestaurantLocation', id)
      this.$emit('locationChanged', id)
      this.selectedRestaurantLocationId = id
    },
  }
}
</script>
