<template>
  <div>
    <!-- Modal -->
    <div class="modal fade"
         data-bs-backdrop="static"
         ref="addCategoryModal"
         id="addCategoryModal"
         tabindex="-1"
         aria-labelledby="addCategoryModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <form @submit.prevent="storeCategory">
            <div class="modal-header">
              <p class="modal-title mb-0" id="addCategoryModalLabel">Add new category</p>
              <button type="button" class="btn-close img-logo" @click="resetAndClose" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="form-group">
                <label for="category-name" class="mb-2">Name</label>
                <input v-model="name"
                       id="category-name"
                       type="text"
                       placeholder="For example: Desserts"
                       :class="['form-control', errors.name ? 'is-invalid' : '']">
                <span v-if="errors.name" class="text-danger">{{ errors.name }}</span>
              </div>

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-link ripple ripple-primary" @click="resetAndClose">
                Cancel & close
              </button>
              <button :disabled="isLoading" type="submit" class="btn btn-primary ripple ripple-primary">
                {{ isLoading ? 'Creating...' : 'Create category' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {db, firebaseAuth} from '@/firebase'
import {collection, addDoc} from 'firebase/firestore'
import {Modal} from 'bootstrap'
import { Toast } from '@/utils'

export default {
  props: ['restaurantLocationId', 'modelValue'],
  data() {
    return {
      name: '',
      errors: {},
      modal: null,
      isLoading: false,
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs['addCategoryModal'])
  },
  watch: {
    modelValue: function (val) {
      if (val) {
        this.modal.show()
      } else {
        this.modal.hide()
      }
    }
  },
  methods: {
    validateForm() {
      this.errors = {}
      let isValid = true
      if (!this.name) {
        isValid = false
        this.errors.name = "The name cannot be empty."
      }
      return isValid
    },
    resetAndClose() {
      this.name = ''
      this.itemImage = null

      this.errors = {}
      this.$emit('update:modelValue', false)
    },
    async storeCategory() {
      this.isLoading = true
      if (!this.validateForm()) {
        this.isLoading = false
        return
      }

      const categoriesRef = collection(db, 'users', firebaseAuth.currentUser.uid, 'restaurantLocations', this.restaurantLocationId, 'categories')
      try {
        await addDoc(categoriesRef, {name: this.name, createdAt: Date.now(), order: 0})

        this.resetAndClose()
        this.$emit('categoryAdded', true)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false

        await Toast.fire({
          icon: 'error',
          title: e.message
        })
      }
    }
  }
}
</script>
