<template>
  <div class="mt-3">

    <AddCategoryModal v-model="showCategoryModal"
                      :restaurant-location-id="restaurantLocationId"
                      @categoryAdded="categoryAdded"/>

    <div class="card card-outline">
      <div class="card-header d-flex justify-content-between mt-2">
        <div class="align-self-center">
          <p class="overline text-muted mb-0">Categories</p>
        </div>
        <div>
          <button @click="showCategoryModal = true"
                  class="btn btn-secondary ripple px-3">
            <i class="material-icons-outlined me-1">&#xe145;</i>
            Add category
          </button>
        </div>
      </div>

      <div class="card-body">
        <div v-if="categoriesArr.length < 1">
          <EmptyState title="No categories" subtitle="You have not created any categories yet."/>
        </div>
        <div v-else>
          <draggable
              class="list-group"
              tag="transition-group"
              :component-data="{
                tag: 'ul',
                type: 'transition-group',
                name: !drag ? 'flip-list' : null
            }"
              v-model="categoriesArr"
              v-bind="dragOptions"
              @start="drag=true"
              @end="drag=false"
              item-key="id">
            <template #item="{element}">
              <li class="list-group-item">
                <div @click="selectCategory(element)"
                     :class="['card card-items', selectedCategory?.id === element?.id ? 'bg-primary' : '']">
                  <div class="card-body d-flex justify-content-between">
                    <div class="d-flex">
                      <div class="align-self-center me-2">
                        <i class="material-icons-outlined">&#xe945;</i>
                      </div>
                      <div class="align-self-center">
                        <p class="subtitle-1 fw-500 mb-0">{{ element.name }}</p>
                      </div>
                    </div>

                    <button @click="deleteMenuCategory(element.id)"
                            class="btn btn-link btn-actions is-delete ripple ripple-primary m-0"
                            :title="'Delete category: ' + element.name">
                      <i class="material-icons-outlined">&#xe872;</i>
                    </button>
                  </div>
                </div>
              </li>
            </template>
          </draggable>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import AddCategoryModal from '@/components/AddCategoryModal'
import EmptyState from '@/components/empty-states/EmptyState'
import {collection, getDocs, doc, updateDoc, query, orderBy, deleteDoc} from 'firebase/firestore'
import {db, firebaseAuth} from "@/firebase";
import Swal from "sweetalert2";
import {Toast} from "@/utils";

export default {
  props: ['restaurantLocationId', 'selectedCategory'],
  components: {
    AddCategoryModal,
    draggable,
    EmptyState
  },
  mounted() {
    this.fetchCategories()
  },
  data() {
    return {
      categoriesArr: [],
      drag: false,
      showCategoryModal: false
    }
  },
  watch: {
    restaurantLocationId: function () {
      this.fetchCategories()
    },
    categoriesWithOrder: function(after, before) {
      if (!before?.length && after.length > 0) {
        return
      }

      // Gets only the items that changed in the "after" array according to the "before" array
      let changedCategories = after.filter(a => !before.some(b => a.id === b.id && a.order === b.order))

      this.saveCategoriesOrder(changedCategories)
    }
  },
  computed: {
    categoriesWithOrder() {
      return this.categoriesArr.map((cat, index) => ({
        id: cat.id,
        order: index + 1,
        name: cat.name,
        createdAt: cat.createdAt,
      }))
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  methods: {
    async saveCategoriesOrder(categories) {
      console.debug(`Saving ${categories.length} categories that changed.`)

      for (let category of categories) {
        try {
          await updateDoc(
              doc(db,
                  'users',
                  firebaseAuth.currentUser.uid,
                  'restaurantLocations',
                  this.restaurantLocationId,
                  'categories',
                  category.id,
              ),
              {order: category.order}
          )
        } catch (e) {
          console.debug("Error while saving menu categories: ", e.message)
        }
      }
    },
    async categoryAdded() {
      await this.fetchCategories()
      await Toast.fire({
        icon: 'success',
        title: 'New category added successfully'
      })
    },
    fetchCategories() {
      const categoriesQuery = query(
          collection(db,
              'users', firebaseAuth.currentUser.uid, 'restaurantLocations', this.restaurantLocationId, 'categories'),
          orderBy('order', 'asc'),
      )

      return getDocs(categoriesQuery)
          .then(querySnap => querySnap.docs)
          .then(docs => docs.map(snap => ({id: snap.id, ...snap.data()})))
          .then(docs => {
            this.categoriesArr = docs
          })
    },
    selectCategory(category) {
      this.$emit('categorySelect', category)
    },
    async deleteMenuCategory(categoryId) {
      const result = await Swal.fire({
        title: 'Deleting a category',
        text: 'This will permanently delete the menu category and all its items. Do you want to continue?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete the category and its items',
        cancelButtonText: 'Cancel',
      })

      if (result.isDismissed || result.isDenied || !result.isConfirmed) {
        return
      }

      const docRef = doc(
          db,
          'users',
          firebaseAuth.currentUser.uid,
          'restaurantLocations',
          this.restaurantLocationId,
          'categories',
          categoryId
      )

      try {
        await deleteDoc(docRef)

        this.$emit('categoryDeleted', categoryId)
        this.categoriesArr = this.categoriesArr.filter(category => categoryId !== category.id)

        await Toast.fire({
          icon: 'success',
          title: 'Category deleted successfully.'
        })
      } catch (err) {
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      }
    }
  }
}
</script>
