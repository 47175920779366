<template>
  <div>
    <!-- Modal -->
    <div class="modal fade"
         data-bs-backdrop="static"
         ref="addItemModal"
         id="addItemModal"
         tabindex="-1"
         aria-labelledby="addItemModalLabel"
         aria-hidden="true">
      <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
        <form @submit.prevent="storeItem" class="modal-content">
          <div class="modal-header">
            <p class="modal-title mb-0" id="addItemModalLabel">Add new item</p>
            <button type="button" class="btn-close img-logo" @click="resetAndClose" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div v-if="errorMessage" class="alert alert-danger">
              {{ errorMessage }}
            </div>

            <div class="mb-3">
              <InputFile id="item-image" label="Item image <small class='text-muted'>(optional)</small>"
                         v-model="itemImage"/>
              <label class="subtitle-2 fw-normal text-muted mt-2">Accepted image types: jpg, jpeg, png</label>
            </div>

            <div class="mb-3">
              <label for="item-name" class="mb-2">Name</label>
              <input v-model="name"
                     id="item-name"
                     type="text"
                     placeholder="For example: Vanilla Cold Brew"
                     :class="['form-control', errors.name ? 'is-invalid' : '']">
              <span v-if="errors.name" class="text-danger">{{ errors.name }}</span>
            </div>

            <div class="mb-3">
              <label for="item-description" class="mb-2">
                Description <small class="text-muted">(optional)</small>
              </label>
              <textarea v-model="description"
                        id="item-description"
                        type="text"
                        placeholder="For example: Coffee infused for 16 hours with a more intense flavor and light vanilla flavor."
                        :class="['form-control', errors.description ? 'is-invalid' : '']">
                </textarea>
              <span v-if="errors.description" class="text-danger">{{ errors.description }}</span>
            </div>

            <div class="row">
              <div class="col-md-6 mb-3">
                <label for="item-name" class="mb-2">Price <small class="text-muted">(optional)</small></label>
                <input v-model="price"
                       id="item-price"
                       type="number"
                       placeholder="For example: 50.00"
                       min="0"
                       :class="['form-control', errors.price ? 'is-invalid' : '']">
                <span v-if="errors.price" class="text-danger">{{ errors.price }}</span>
              </div>

              <div class="col-md-6 mb-3">
                <label for="item-category-id" class="mb-2">Category</label>
                <select v-model="selectedCategoryId" id="item-category-id"
                        :class="['form-control', errors.categoryId ? 'is-invalid' : '']">
                  <option value="" selected disabled>Select a category for the item</option>
                  <option v-for="(category, index) in categoriesArr"
                          :key="index+'-item-category'"
                          :value="category.id">{{ category.name }}
                  </option>
                </select>

                <span v-if="errors.categoryId" class="text-danger">{{ errors.categoryId }}</span>
              </div>
            </div>

            <label class="mb-3">Dietary restrictions <small class="text-muted">(optional)</small></label>
            <div class="row">
              <div class="col-md-12 mb-3">
                <div class="form-check-inline">
                  <input class="btn-check" type="checkbox" v-model="isVegan" id="vegan-checkbox">
                  <label class="btn btn-chips" for="vegan-checkbox">
                    Vegan
                  </label>
                </div>

                <div class="form-check-inline">
                  <input class="btn-check" type="checkbox" v-model="isVegetarian" id="vegetarian-checkbox">
                  <label class="btn btn-chips" for="vegetarian-checkbox">
                    Vegetarian
                  </label>
                </div>

                <div class="form-check-inline">
                  <input class="btn-check" type="checkbox" v-model="isGlutenFree" id="gluten-free-checkbox">
                  <label class="btn btn-chips" for="gluten-free-checkbox">
                    Gluten free
                  </label>
                </div>
              </div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-link ripple ripple-primary" @click="resetAndClose">
              Cancel & close
            </button>
            <button :disabled="isLoading" type="submit" class="btn btn-primary ripple ripple-primary">
              {{ isLoading ? 'Creating...' : 'Create item' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {Modal} from 'bootstrap'
import InputFile from "@/components/InputFile";
import {addDoc, collection, getDocs, orderBy, query, updateDoc} from "firebase/firestore";
import {db, firebaseAuth} from "@/firebase";
import {getStorage, ref, uploadBytes, getDownloadURL} from "firebase/storage";

export default {
  name: "AddItemModal",
  components: {InputFile},
  props: ['restaurantLocationId', 'modelValue', 'preselectedCategoryId'],
  data() {
    return {
      itemImage: null,
      name: '',
      description: '',
      price: '',
      selectedCategoryId: null,

      isVegan: false,
      isVegetarian: false,
      isGlutenFree: false,

      categoriesArr: [],
      errors: {},
      errorMessage: null,
      modal: null,
      isLoading: false,
    }
  },
  computed: {
    selectedCategory() {
      return this.categoriesArr.find(category => category.id === this.selectedCategoryId)
    }
  },
  mounted() {
    this.selectedCategoryId = this.preselectedCategoryId
    this.modal = new Modal(this.$refs['addItemModal'])
    this.fetchCategories()
  },
  watch: {
    modelValue: function (val) {
      if (val) {
        this.modal.show()
      } else {
        this.modal.hide()
      }
    }
  },
  methods: {
    fetchCategories() {
      console.debug("Fetching categories to add item...")
      const categoriesQuery = query(
          collection(db,
              'users', firebaseAuth.currentUser.uid, 'restaurantLocations', this.restaurantLocationId, 'categories'),
          orderBy('order', 'asc'),
      )

      return getDocs(categoriesQuery)
          .then(querySnap => querySnap.docs)
          .then(docs => docs.map(snap => ({id: snap.id, ...snap.data()})))
          .then(docs => {
            this.categoriesArr = docs
          })
    },
    validateForm() {
      this.errors = {}
      this.errorMessage = null
      let isValid = true
      if (!this.name) {
        isValid = false
        this.errors.name = "The name cannot be empty."
      }

      if (!this.selectedCategoryId && !this.selectedCategory) {
        isValid = false
        this.errors.categoryId = "Select a category."
      }

      return isValid
    },
    resetAndClose() {
      this.name = ''
      this.description = ''
      this.price = ''
      this.itemImage = null
      this.isVegan = false
      this.isVegetarian = false
      this.isGlutenFree = false

      this.errors = {}
      this.$emit('update:modelValue', false)
    },
    async storeItem() {
      this.isLoading = true

      if (!this.validateForm()) {
        this.isLoading = false
        return
      }

      try {
        const itemsRef = collection(db,
            'users',
            firebaseAuth.currentUser.uid,
            'restaurantLocations',
            this.restaurantLocationId,
            'items')

        const itemDocSnap = await addDoc(itemsRef, {
          name: this.name,
          description: this.description,
          price: this.price || null,
          createdAt: Date.now(),
          categoryId: this.selectedCategoryId,
          categoryName: this.selectedCategory.name,
          order: 0,
          isVegan: this.isVegan,
          isVegetarian: this.isVegetarian,
          isGlutenFree: this.isGlutenFree,
        })

        if (this.itemImage) {
          const mimeType = this.itemImage.name.split(".")[1]
          const storage = getStorage()
          const path = `users/${firebaseAuth.currentUser.uid}/menuItems/${itemDocSnap.id}.${mimeType}`
          const storageRef = ref(storage, path)
          const downloadURL = await uploadBytes(storageRef, this.itemImage).then((snapshot) => getDownloadURL(snapshot.ref))


          await updateDoc(itemDocSnap, {
            imageURL: downloadURL,
            imageStoragePath: path,
          })
        }

        this.resetAndClose()
        this.$emit('itemAdded', true)
      } catch (e) {
        console.error("An error occurred:", e)
        this.errorMessage = "Oops, an unexpected error occurred. Please, reload the page and try again."
      }

      this.isLoading = false
    }
  }
}
</script>
