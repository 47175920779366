<template>
  <div class="mt-3">

    <AddItemModal v-if="selectedCategory" v-model="showItemModal"
                  :restaurant-location-id="restaurantLocationId"
                  :preselected-category-id="selectedCategory.id"
                  @itemAdded="itemAdded"/>

    <div class="card card-outline">
      <div class="card-header d-flex justify-content-between mt-2">
        <div class="align-self-center">
          <p class="overline text-muted mb-0">Items</p>
        </div>
        <div>
          <button v-if="selectedCategory" @click="showItemModal = true"
                  class="btn btn-secondary ripple px-3">
            <i class="material-icons-outlined me-1">&#xe145;</i>
            Add item
          </button>
        </div>
      </div>

      <div v-if="selectedCategory" class="card-body">
        <div v-if="itemsArr.length < 1">
          <EmptyState title="No items" subtitle="There's no items in this category."/>
        </div>
        <div v-else>
          <draggable
              class="list-group"
              tag="transition-group"
              :component-data="{
                tag: 'ul',
                type: 'transition-group',
                name: !drag ? 'flip-list' : null
            }"
              v-model="itemsArr"
              v-bind="dragOptions"
              @start="drag=true"
              @end="drag=false"
              item-key="id">
            <template #item="{element}">
              <li class="list-group-item">
                <div class="card card-items">
                  <div class="card-body d-flex">
                    <div class="align-self-center me-2">
                      <i class="material-icons-outlined">&#xe945;</i>
                    </div>
                    <div class="align-self-center flex-grow-1">
                      <div class="d-flex">
                        <div class="align-self-center me-2">
                          <img :src="element?.imageURL ?? 'https://via.placeholder.com/150'" class="img-items"
                               width="50"
                               height="50" :title="element.name + ' Image'"
                               :alt="element.name + ' Image'">
                        </div>
                        <div class="align-self-center">
                          <p class="subtitle-1 fw-500 mb-1">{{ element.name }}</p>
                          <p class="subtitle-2 text-muted  mb-0"
                             :title="element.description">
                            {{ element.description }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="align-self-center">
                      <p class="badge badge-secondary px-3 mb-0">
                        {{ !element.price ? 'No price listed' : `\$${element.price}` }}
                      </p>

                      <button @click="deleteMenuItem(element.id)"
                              class="btn btn-link btn-actions is-delete ripple ripple-primary m-0"
                              :title="'Delete item: ' + element.name">
                        <i class="material-icons-outlined">&#xe872;</i>
                      </button>
                    </div>
                  </div>
                </div>
              </li>
            </template>
          </draggable>
        </div>
      </div>
      <div v-else class="card-body">
        <EmptyState title="No category selected" subtitle="First select a category to list its items."/>
      </div>

    </div>

  </div>
</template>

<script>
import draggable from 'vuedraggable'
import AddItemModal from "@/components/AddItemModal";
import {collection, doc, getDocs, orderBy, query, updateDoc, where, deleteDoc} from "firebase/firestore";
import {db, firebaseAuth} from "@/firebase";
import {Toast} from "@/utils"
import EmptyState from "@/components/empty-states/EmptyState";
import Swal from "sweetalert2";

export default {
  props: ['restaurantLocationId', 'selectedCategory'],
  components: {
    EmptyState,
    AddItemModal,
    draggable
  },
  mounted() {
    this.fetchItems()
  },
  data() {
    return {
      itemsArr: [],
      drag: false,
      showItemModal: false
    }
  },
  computed: {
    itemsWithOrder() {
      return this.itemsArr.map((item, index) => ({
        id: item.id,
        order: index + 1,
        name: item.name,
        createdAt: item.createdAt,
      }))
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    }
  },
  watch: {
    restaurantLocationId: function () {
      this.fetchItems()
    },
    itemsWithOrder: function (after, before) {
      if (!before?.length && after.length > 0) {
        return
      }

      // Gets only the items that changed in the "after" array according to the "before" array
      let changedItems = after.filter(a => !before.some(b => a.id === b.id && a.order === b.order))

      this.saveItemsOrder(changedItems)
    },
    selectedCategory: function () {
      this.fetchItems()
    }
  },
  methods: {
    async saveItemsOrder(changedItems) {
      console.debug(`Saving ${changedItems.length} items that changed.`)
      for (let item of changedItems) {
        try {
          await updateDoc(
              doc(db,
                  'users',
                  firebaseAuth.currentUser.uid,
                  'restaurantLocations',
                  this.restaurantLocationId,
                  'items',
                  item.id,
              ),
              {order: item.order}
          )
        } catch (e) {
          console.debug("Error while saving items order: ", e.message)
        }
      }
    },
    async itemAdded() {
      await this.fetchItems()
      await Toast.fire({
        icon: 'success',
        title: 'New item added successfully'
      })
    },
    fetchItems() {
      if (!this.selectedCategory) {
        return
      }

      const itemsQuery = query(
          collection(db,
              'users',
              firebaseAuth.currentUser.uid,
              'restaurantLocations',
              this.restaurantLocationId,
              'items'),
          where('categoryId', '==', this.selectedCategory.id),
          orderBy('order', 'asc')
      )

      return getDocs(itemsQuery)
          .then(querySnap => querySnap.docs)
          .then(docs => docs.map(snap => ({id: snap.id, ...snap.data()})))
          .then(docs => {
            this.itemsArr = docs
          })
    },
    async deleteMenuItem(itemId) {
      const result = await Swal.fire({
        title: 'Deleting an item',
        text: 'This will permanently delete the menu item and its image. Do you want to continue?',
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Delete the item',
        cancelButtonText: 'Cancel',
      })

      if (result.isDismissed || result.isDenied || !result.isConfirmed) {
        return
      }

      const docRef = doc(
          db,
          'users',
          firebaseAuth.currentUser.uid,
          'restaurantLocations',
          this.restaurantLocationId,
          'items',
          itemId
      )

      try {
        await deleteDoc(docRef)

        this.itemsArr = this.itemsArr.filter(item => itemId !== item.id)

        await Toast.fire({
          icon: 'success',
          title: 'Item deleted successfully.'
        })
      } catch (err) {
        await Toast.fire({
          icon: 'error',
          title: err.message
        })
      }
    }
  }
}
</script>
